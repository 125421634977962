import { NAME } from '@/app/navigation/constants';

import { PlusIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import { WorkspaceIcon } from '@/app/navigation/components/New/WorkspaceSelector/WorkspaceIcon';
import { WorkspaceSelectorActions } from '@/app/navigation/components/New/WorkspaceSelector/WorkspaceSelectorActions';
import { WorkspaceSelectorDropdownEntry } from '@/app/navigation/components/New/WorkspaceSelector/WorkspaceSelectorDropdownEntry';
import { getWorkspaceBackgroundColor } from '@/app/navigation/helpers';

import { IconWrapper } from './IconWrapper';

import type { Props as WorkspaceSelectorActionsProps } from '@/app/navigation/components/New/WorkspaceSelector/WorkspaceSelectorActions';
import type { WorkspaceResource } from '@/app/workspaces/types';
import type { ReactNode } from 'react';

export interface Props extends WorkspaceSelectorActionsProps {
    workspaces: WorkspaceResource[];
    activeWorkspace: WorkspaceResource;
    actions?: ReactNode;
    showAddNewWorkspaceButton?: boolean;
    showActions: boolean;
    onWorkspaceSelected: (workspace: WorkspaceResource) => void;
    onAddNewWorkspace: () => void;
    onAllWorkspacesViewClicked: () => void;
    isAllWorkspacesViewOpen: boolean;
}

export const WorkspaceSelectorDropdown = ({
    workspaces,
    activeWorkspace,
    showAddNewWorkspaceButton,
    showActions,
    manageMembersClicked,
    workspaceSettingsClicked,
    onWorkspaceSelected,
    onAddNewWorkspace,
    onAllWorkspacesViewClicked,
    isAllWorkspacesViewOpen,
}: Props) => {
    const { t } = useTranslation(NAME);

    return (
        <>
            {showActions && !isAllWorkspacesViewOpen && (
                <WorkspaceSelectorActions
                    manageMembersClicked={manageMembersClicked}
                    workspaceSettingsClicked={workspaceSettingsClicked}
                />
            )}

            <div className="p-2">
                <WorkspaceSelectorDropdownEntry className="text-gray-500 hover:text-gray-500">
                    {t('switch-workspace')}
                </WorkspaceSelectorDropdownEntry>

                <WorkspaceSelectorDropdownEntry
                    link="/workspaces"
                    onClick={onAllWorkspacesViewClicked}
                >
                    <IconWrapper showOutline={isAllWorkspacesViewOpen}>
                        <Squares2X2Icon className="size-4" />
                    </IconWrapper>
                    <span>{t('all-workspaces')}</span>
                </WorkspaceSelectorDropdownEntry>

                {workspaces.map((workspace) => {
                    const { id, attributes } = workspace;

                    return (
                        <WorkspaceSelectorDropdownEntry
                            key={id}
                            link={`/workspaces/${id}`}
                            onClick={() => {
                                onWorkspaceSelected(workspace);
                            }}
                        >
                            <WorkspaceIcon
                                name={attributes?.name}
                                bgColor={getWorkspaceBackgroundColor(attributes?.name)}
                                active={id === activeWorkspace.id && !isAllWorkspacesViewOpen}
                                logoSrc={attributes?.logo}
                            />

                            <span>{attributes?.name}</span>
                        </WorkspaceSelectorDropdownEntry>
                    );
                })}

                {showAddNewWorkspaceButton && (
                    <WorkspaceSelectorDropdownEntry onClick={onAddNewWorkspace}>
                        <IconWrapper>
                            <PlusIcon className="size-4" />
                        </IconWrapper>

                        <span>{t('new-workspace')}</span>
                    </WorkspaceSelectorDropdownEntry>
                )}
            </div>
        </>
    );
};
